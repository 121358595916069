import Vue from 'vue';
function setRem() {
  // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  //得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0];
  //设置根元素字体大小
  // 23.439
  htmlDom.style.fontSize = htmlWidth / 32 + 'px';
}
// 初始化字体
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
//设置meta
const metaArr = document.getElementsByTagName('meta');
const meta = [].find.call(metaArr, v => v.name == 'viewport');
meta.content = meta.content + ',viewport-fit=cover';
//全局注册组件
Vue.component('Common', () => import('@/components/mobile/Common'));
// let VConsole = await import('vconsole')
// VConsole = VConsole.default
// import VConsole from "vconsole"
// new VConsole({ theme: 'dark' });
